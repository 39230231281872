function getMapkitToken(origin: string) {
  console.log(origin);
  if (origin.endsWith('pages.dev')) {
    return 'eyJraWQiOiI2UFZXWU5USDc3IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJVRzU1Q004UzROIiwiaWF0IjoxNzMxMDg0NTgwLCJvcmlnaW4iOiIqLnBhZ2VzLmRldiJ9.ZsCABfZdE5TMJcg_-SZkVB_mu0U8HjeWPfq1auFN86e3h6DNoUKXYVXGRP46fmkmpXgY-sFEV9sU8W1kM8KwWg';
  }
  if (origin.endsWith('wheretaken.com')) {
    return 'eyJraWQiOiJBOE03NkNLM0E1IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiIyRlpYTDRHNzdaIiwiaWF0IjoxNzMxMDgzMDc5LCJvcmlnaW4iOiJ3aGVyZXRha2VuLmNvbSJ9.5mM_fP_PWbAAbT210GjP8MZCFm7lTQcutkkEp_CSjYvUdupx7ru1Vq4oIru_m4KKuQxyUaFBiR7gHoJOq5xr_w';
  }
  if (origin.endsWith('movies.whentaken.com')) {
    return 'eyJraWQiOiJHVkZVUEJMNUdNIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiIyRlpYTDRHNzdaIiwiaWF0IjoxNzMyODc0OTc0LCJvcmlnaW4iOiJtb3ZpZXMud2hlbnRha2VuLmNvbSJ9.hGNiqKWeW45SjJxrAfFSHagAhV6xEfDT-U-BP8aeJTuxhLspqwuQC-qtDsWJsbH_uM3AfV7y2yxx8E51AFbD0Q';
  }
  if (origin.endsWith('whentaken.com')) {
    return 'eyJraWQiOiJHMkdBSjg0Mkg0IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiIyRlpYTDRHNzdaIiwiaWF0IjoxNzMxMDgzMDc5LCJvcmlnaW4iOiJ3aGVudGFrZW4uY29tIn0.yYkCr1pkifTLoDpKWt3Q-OGLWkeJ_6D6fCZCqnv0G_OF8-ZYVYY_tcSwjxu_bh9lPxbINXmJ2DYWQqVDw6uy0Q';
  }
  if (origin.endsWith('localhost:3000')) {
    return 'eyJraWQiOiI0UVFCTTYzODc1IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiIyRlpYTDRHNzdaIiwiaWF0IjoxNzM1NjQwMjEyLCJleHAiOjE3MzYzMjMxOTl9.tc_gYhFjBX3wG-2vC5cRZZoWLKFdd0tuc0IVf2cXtnJ6wxUAgCPKtsdbo81wIyh5nClf-yliIIoQ0DkukXxyvA';
  }
  return '';
}

export { getMapkitToken };
